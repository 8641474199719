import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import Hero from '../CMSBlocks/Hero';
import SEO from '../SEO';
import Container from '../Container';
import CopyBlock from '../CopyBlock';

const NewsArticlePage = ({
	articleImageUrl,
	title,
	subHeading,
	downloadUrl,
	articleCopy,
	slug,
	versionDate,
	statementSignOffMdx,
	statementSignOff,
	parentBreadcrumb,
	parentBreadcrumbTitle,
	parentBreadcrumbSlug,
}) => {
	const location = useLocation();
	return (
		<>
			<SEO
				path={location.pathname}
				title={title}
				description={subHeading}
				meta={[
					{
						name: `og:image`,
						content: articleImageUrl,
					},
				]}
				isMultisite
			/>

			<Helmet>
				<link rel="canonical" href={`${slug}`} />
			</Helmet>

			<Hero
				title={title}
				fullWidth
				breadcrumbs={
					parentBreadcrumb && {
						parent: {
							title: parentBreadcrumbTitle,
							url: parentBreadcrumbSlug,
						},
					}
				}
			/>

			<section>
				<Container>
					<div className="flex flex-col-reverse md:grid-cols-6 md:grid">
						<div className="col-span-4 py-5 pr-5">
							<CopyBlock>{articleCopy.childMdx.body}</CopyBlock>

							{statementSignOff !== '' && (
								<div className="flex justify-end">
									<div className="w-full p-6 mt-8 border-l-4 border-brand-200 bg-brand-600">
										<CopyBlock>{statementSignOffMdx.childMdx.body}</CopyBlock>
									</div>
								</div>
							)}
						</div>

						<div className="col-span-2 p-5">
							{versionDate && (
								<h4 className="pt-8 pb-8 text-lg font-bold lg:text-xl md:pt-0">
									Version Date: {versionDate}
								</h4>
							)}
							{downloadUrl && (
								<div className="pb-5">
									<a
										href={downloadUrl}
										className="items-center justify-between px-10 py-3 my-2 text-sm font-bold text-center border-2 rounded w-fit text-brand-200 hover:text-brand-400 border-secondary-400"
										download
										target="_blank"
										rel="noreferrer"
									>
										Download
									</a>
								</div>
							)}
						</div>
					</div>
				</Container>
			</section>
		</>
	);
};

export default NewsArticlePage;
