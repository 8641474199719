import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import NewsArticlePage from '../components/NewsArticlePage/statements';

const StatementPage = ({ data }) => {
	const statement = data.datoCmsCompanyStatement;

	return (
		<>
			<NewsArticlePage
				parentBreadcrumb
				parentBreadcrumbTitle="Section 172 (1) Statements"
				parentBreadcrumbSlug="/section-172-1-statements"
				articleImageUrl={statement.articleImage?.url}
				title={statement.title}
				subHeading={statement.subHeading}
				downloadUrl={statement.downloadCopy.url}
				articleCopy={statement.contentNode}
				slug={statement.slug}
				versionDate={statement.versionDate}
				statementSignOffMdx={statement.statementSignOffNode}
				statementSignOff={statement.statementSignOff}
			/>
		</>
	);
};

export const query = graphql`
	query companyStatement($id: String) {
		datoCmsCompanyStatement(slug: { ne: null }, id: { eq: $id }) {
			title
			slug
			contentNode {
				childMdx {
					body
				}
			}
			statementSignOff
			statementSignOffNode {
				childMdx {
					body
				}
			}
			downloadCopy {
				url
			}
			versionDate
			seo {
				title
				description
				image {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 450
						imgixParams: { auto: "compress" }
					)
				}
			}
		}
	}
`;

const WrappedStatementPage = (props) => (
	<DefaultLayout fullWidth>
		<StatementPage {...props} />
	</DefaultLayout>
);

export default WrappedStatementPage;
